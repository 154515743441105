import React from "react"

import loadable from "@loadable/component"

const HourlyMethaneClientComponent =  loadable(() => import('./hourlyMethaneClient'))

const HourlyMethaneGraph = () => {
  const source = "https://s3.us-east-2.amazonaws.com/uratmos.org/data/nys_methane.csv"
  
  const options = {
    colors: ["#E41A1C","#377EB8","#4DAF4A","#FF7F00"],
    connectSeparatedPoints: false,
    customBars: false,
    dateWindow: [ Date.now()-(75*24*3600*1000), Date.now() ],
    fillAlpha: 0.2,
    fillGraph: true,
    legend: 'always',
    showRangeSelector: true,
    stepPlot: true,
    title: "Hourly Mean Methane Concentrations",
    valueRange: [ 1.85, 2.5 ],
    xlabel: "Local Time",
    ylabel: 'Methane (ppmv)',
  }

  return (
    <div className="card research">
      <div className="card-image">
        <figure className="research">
          <HourlyMethaneClientComponent
            source={source}
            div="chart"
            options={options} 
          />
        </figure>
      </div>
      <div className="card-content">
        <div className="content">
          <span className="title is-6">
            Fig. 1 - Historical methane concentrations.
          </span>
          <br />
          Time evolution of methane in ambient surface air of New York. Use slider bar to adjust period of view. Values preliminary: please contact us before any use. Missing data reflects instrument down time, e.g., due to repair or power outages.
        </div>
      </div>
    </div>
  )
}

export default HourlyMethaneGraph