import React from "react"
import { StaticQuery, graphql } from "gatsby"

import loadable from "@loadable/component"


const MapDomComponent =  loadable(() => import('./MapDOMComponent'))

const LightningMap = class extends React.Component {
  render() {
    return (
      <StaticQuery 
        query={graphql`
          query LightningJson {
            file(base: {eq: "world-110m.json"}) {
              publicURL
            }
            lightningJson {
              title
              loading
              layers {
                dataFile {
                  publicURL
                }
                caption
                button
                title
                index
                domain
                range
              }
            }
          }
        `}
        render={data => (
          <div className="d3mapContainer">
            <MapDomComponent countries={data.file} data={data.lightningJson} id="lightningMap" />
          </div>
        )}
      />
    )
  }
}

export default LightningMap