import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import ExternalLink from "../layout/externalLink"

export const LightningImage = () => (
  <StaticQuery
    query={graphql`query LightningImageFooterQuery {
  footer: file(relativePath: {eq: "wwlln.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        placeholder: BLURRED
      )
    }
  }
}
`}
    render={data => (
      <div className="columns">
        <div className="column">
          <div className="card research">
            <div className="card-image">
              <figure className="research full-bleed">
                <GatsbyImage image={getImage(data.footer)} />
              </figure>
            </div>
            <div className="card-content">
              <div className="content">
                <span className="title is-6">
                  Figure 2:
                </span>
                {` `}
                Our
                {` `}
                <ExternalLink title="WWLLN" path="http://www.wwlln.net">WWLLN</ExternalLink>
                {` `}
                VLF antenna on top of Hylan Hall, overlooking the Genesee River, campus and downtown Rochester.
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
  />
)

export default LightningImage