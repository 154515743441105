import React from "react"
import PropTypes from "prop-types"

const ExternalLink = ({ path, title, className, children }) => (
  <a 
    className={`${className === undefined ? '' : className}`}
    href={path} 
    rel="noopener noreferrer" 
    target="_blank" 
    title={title}
  >
    {children}
  </a>
)

ExternalLink.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string
}

ExternalLink.defaultProps = {
  title: ``,
}

export default ExternalLink