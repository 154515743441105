// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-data-jsx": () => import("./../../../src/pages/data.jsx" /* webpackChunkName: "component---src-pages-data-jsx" */),
  "component---src-pages-education-jsx": () => import("./../../../src/pages/education.jsx" /* webpackChunkName: "component---src-pages-education-jsx" */),
  "component---src-pages-giss-gc-jsx": () => import("./../../../src/pages/giss-gc.jsx" /* webpackChunkName: "component---src-pages-giss-gc-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-jobs-jsx": () => import("./../../../src/pages/jobs.jsx" /* webpackChunkName: "component---src-pages-jobs-jsx" */),
  "component---src-pages-news-jsx": () => import("./../../../src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */),
  "component---src-pages-people-jsx": () => import("./../../../src/pages/people.jsx" /* webpackChunkName: "component---src-pages-people-jsx" */),
  "component---src-pages-publications-jsx": () => import("./../../../src/pages/publications.jsx" /* webpackChunkName: "component---src-pages-publications-jsx" */),
  "component---src-pages-research-jsx": () => import("./../../../src/pages/research.jsx" /* webpackChunkName: "component---src-pages-research-jsx" */),
  "component---src-templates-topic-jsx": () => import("./../../../src/templates/topic.jsx" /* webpackChunkName: "component---src-templates-topic-jsx" */)
}

