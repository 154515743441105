import React from "react"

import { Helmet } from "react-helmet"

import loadable from "@loadable/component"

const LeafletComponent =  loadable(() => import('./leaflet'))

const NewYorkMethaneComponent = () => {
  
  const options = {
    position: [ 42.6526, -75.2327 ],
    zoom: 6,
  }

  return (
    <div className="card research">
      <div className="card-image">
        <figure className="research">
          <Helmet>
            <link 
              rel="stylesheet" 
              href="https://unpkg.com/leaflet@1.5.1/dist/leaflet.css" 
              integrity="sha512-xwE/Az9zrjBIphAcBb3F6JVqxf46+CDLwfLMHloNu6KEQCAWi6HcDUbeOfBIptF7tcCzusKFjFw2yuvEpDL9wQ==" 
              crossOrigin=""
            />
          </Helmet>
          <LeafletComponent
            div="map"
            source="https://s3.us-east-2.amazonaws.com/uratmos.org/data/nys_methane_map.json"
            options={options} 
          />
        </figure>
      </div>
      <div className="card-content">
        <div className="content">
          <span className="title is-6">
            Fig. 2 - Recent methane abundance in surface air
          </span>
          <br />
            The most recent surface mixing ratio of methane available at each of our monitoring stations. Values shown are in parts per million per volume air (ppmv). Mouse over for additional station information.
        </div>
      </div>
    </div>
  )
}

export default NewYorkMethaneComponent